<template>
  <div class="app-container global-flex-col_container">
    <eHeader :menus="distors" style="margin-bottom: 10px" :sup_this="sup_this" :query="query" />

    <AuthBtn v-if="checkPermission('externaladmin:user:list:unApproveTotal')" />
    <!--表格渲染-->
    <div class="table-wrapper global-flex_1">
      <CommonTable
        :tableLoading="tableLoading"
        :cols="cols"
        :infoData="data"
        ref="multipleTable"
        :selection="false"
        :isExpand="true"
        height="100%"
        @expand-change="expandChange"
        @selection-change="handleSelectionChange"
        @select-all="selectAll"
      >
        <template v-if="permission.getInf" #expand="{ scoped: row }">
          <div v-if="!showExpand[`${row.id}`]">该分销商暂无子账号</div>
          <div v-else>
            <el-table :data="expandData[`${row.id}`]" border v-loading="loading">
              <el-table-column label="子账号名称" prop="nickName"> </el-table-column>
              <el-table-column label="登录账号" prop="accountName"> </el-table-column>
              <el-table-column label="创建时间" prop="createTime">
                <template v-slot="{ row }">
                  {{ row.createTime | formatTime }}
                </template>
              </el-table-column>
              <el-table-column label="激活状态" prop="isDisabled">
                <template v-slot="{ row }">
                  <el-tag v-if="row.isDisabled" size="small" type="primary"> 是 </el-tag>
                  <el-tag v-else size="small" type="danger"> 否 </el-tag>
                </template>
              </el-table-column>
              <el-table-column label="操作" prop="menu">
                <template v-slot="{ row }">
                  <disabledBtn :data="row"></disabledBtn>
                </template>
              </el-table-column>
            </el-table>
            <PaginationBar
              :page="expandPage[row.id].pageIndex"
              :total="expandPageTotal[row.id]"
              @refreshTableEventFun="expandRefreshTableEventFun($event, row)"
            />
          </div>
        </template>
        <template #typeSlot="{ scoped: row }">
          {{ row.type_name }}
        </template>
        <template #vipSlot="{ scoped: row }">
          {{ levelName(row) }}
        </template>
        <template #date_joinedSlot="{ scoped: row }">
          {{ row.createTime | formatTime }}
        </template>
        <template #isPayOrderSlot="{ scoped: row }">
          <el-tag v-if="!row[row.prop]" size="small" type="primary"> 是 </el-tag>
          <el-tag v-else size="small" type="danger"> 否 </el-tag>
        </template>
        <template #is_activeName="{ scoped: row }">
          <el-tag v-if="row[row.prop]" size="small" type="primary"> 是 </el-tag>
          <el-tag v-else size="small" type="danger"> 否 </el-tag>
        </template>
        <template #userNatureSlot="{ scoped: row }">
          {{ getUserNature(row) }}
        </template>
        <template #isCanTortSlot="{ scoped: row }">
          {{ isCanTort(row) }}
        </template>
        <template #rolesSlot="{ scoped: row }">
          <el-tag style="margin: 5px" type="primray" size="mini" :key="index" v-for="(item, index) in handleRole(row)">
            {{ item }}
          </el-tag>
        </template>
        <template #prototypePermissionTypeSlot="{ row }">
          {{ findByValue(PROTOTYPE_DIC, row.prototypePermissionType) }}
        </template>

        <slot>
          <el-table-column label="操作" width="250px" align="center">
            <template slot-scope="scope">
              <tableOperation dropdown-menu-class="order-operation-dropdown">
                <edit v-if="permission.edit" :menus="distors" :data="scope.row" :sup_this="sup_this" />
                <updatePass v-if="permission.changePassword" :data="scope.row" :sup_this="sup_this"></updatePass>
                <resellerInfo v-if="permission.getInformation" :data="scope.row" :id="scope.row.id"></resellerInfo>
                <template #SingleChangeLevelBtn>
                  <SingleChangeLevelBtn
                    v-if="permission.changeVIP"
                    :id="scope.row.id"
                    :VIPLevelList="VIPLevelList"
                    :init="init"
                  ></SingleChangeLevelBtn>
                </template>
                <exportTradeRecord v-if="permission.tradeRecord" :data="[scope.row]"></exportTradeRecord>
                <span v-if="permission.controlWaybillCode" class="text-mini" @click="controlWaybillCode(scope.row)"
                  >运单号显示</span
                >
                <span v-if="permission.updateEnabledCredit" class="text-mini" @click="openCreditAmount(scope.row)"
                  >{{ scope.row.isEnabledCredit === 1 ? '关闭' : '启用' }}信用额度</span
                >
                <ColorTextBtn v-if="permission.setTort" @click="showDialog(scope.row)">设置侵权权限</ColorTextBtn>
                <LogoutBtn
                  :init="init"
                  :data="scope.row"
                  v-if="checkPermission('externaladmin:user:list:morethanBtn:cancellation')"
                ></LogoutBtn>
                <ColorTextBtn
                  class="frozenOrThawClass"
                  @click="goFrozenOrThaw(scope.row.id)"
                  v-if="checkPermission('externaladmin:user:list:morethanBtn:warningAndFreeze')"
                  >{{ frozenOrThaw(scope.row) }}</ColorTextBtn
                >
              </tableOperation>
            </template>
          </el-table-column>
        </slot>
      </CommonTable>
      <tortDialog v-if="visible" :visible.sync="visible" :sup_this="sup_this" :selectedData="selectedData" />
    </div>

    <PaginationBar :page="page" :size="size" :total="total" @refreshTableEventFun="refreshTableEventFun" />
  </div>
</template>

<script>
import AuthBtn from '@/views/components/authentication/authBtn'
import tableOperation from '@/views/components/table/tableOperation'
import { updateEnabledCredit } from '@/api/financial/userCredit'
// import BatchChangeLevelBtn from './BatchChangeLevelBtn'
import SingleChangeLevelBtn from './SingleChangeLevelBtn'
import initDataMixin from '@/mixins/initData'
import resellerInfo from './module/resellerInfo.vue'
import { parseTime } from '@/utils'
import eHeader from './module/header'
import disabledBtn from '@/views/components/disabledBtn'
import edit from './module/edit'
import updatePass from './module/updatePass'
import Assign from './module/assign'
import exportTradeRecord from './module/exportTradeRecord'
import cols from './cols'
import { subAccount, updateWaybillStatus } from './resellerPage/resellerAPI'
import { getVipList } from '@/api/vipLevel'
import LogoutBtn from './MoreThanBtn'
import { IN_DISPATCH, IN_PRODUCTION, DELIVER, PROTOTYPE_DIC } from '@/utils/constant'
import { find } from 'lodash'

import tortDialog from './module/tortDialog'
import { permission } from '@/views/user/const'
import { ENABLE_CREDIT, DISABLE_CREDIT } from './creditConst'

export default {
  mixins: [initDataMixin],
  components: {
    tableOperation,
    eHeader,
    edit,
    updatePass,
    Assign,
    resellerInfo,
    disabledBtn,
    // BatchChangeLevelBtn,
    SingleChangeLevelBtn,
    LogoutBtn,
    AuthBtn,
    tortDialog,
    exportTradeRecord
  },
  data() {
    this.permission = permission
    return {
      cols,
      PROTOTYPE_DIC,
      delLoading: false,
      url: '/externaladmin/authService/user/distributorUserList',
      method: 'post',
      sup_this: this,
      distors: [],
      treeIds: [],
      expandData: {},
      loading: false,
      showExpand: {},
      expandPage: {},
      expandPageTotal: {},
      query: {
        isProductPublic: ''
      },
      selectionData: [],
      allSelectLength: 0,
      selectionAll: false,
      isIndeterminateData: false,
      VIPLevelList: [],
      userNatureList: [
        { label: '内部', value: 0 },
        { label: '余额', value: 1 },
        { label: '赊账', value: 2 }
      ],
      tortList: [
        { label: '全部', value: 'all' },
        { label: '是', value: 1 },
        { label: '否', value: 0 }
      ],
      selectedData: {},
      visible: false,
      mergeData: {
        orderItems: [{ asc: false, column: 'create_time' }]
      }
    }
  },
  watch: {
    page: {
      handler(newVal) {
        this.allSelectLength = 0
        this.selectionAll = false
        this.pageChangeValue = false
      },
      deep: true
    },
    size: {
      handler(newVal) {
        this.allSelectLength = 0
        this.selectionAll = false
        this.pageChangeValue = false
      },
      deep: true
    }
  },

  computed: {
    getUserNature() {
      return (row) => {
        let userNatureData = ['内部', '余额', '赊账']
        return userNatureData[row.userNature] ?? '暂无'
      }
    },
    levelName() {
      return (row) => {
        return row?.paymentAccountSimple?.memberLevel?.levelName || '暂无'
      }
    },
    frozenOrThaw() {
      return (row) => {
        if (row.userStatus) {
          return '解冻'
        } else {
          return '警告/冻结'
        }
      }
    }
  },
  methods: {
    // 去冻结/解冻页面
    goFrozenOrThaw(id) {
      this.$router.push({
        path: '/user/ThawAndFree',
        query: {
          id
        }
      })
    },
    parseTime,
    // 获取会员等级列表
    async getVIPLevelList() {
      const { detail } = await getVipList({ page: { pageSize: 0, pageIndex: 1 } })
      this.VIPLevelList = detail || []
    },
    isCanTort({ isCanTort }) {
      switch (isCanTort) {
        case 0:
          return '否'
        case 1:
          return '是'
      }
    },
    showDialog(data) {
      this.selectedData = data
      this.visible = true
    },
    handleRole(data) {
      const roles = data.roleDTOList || []
      const role = roles
        .map((role) => {
          return role?.roleName
        })
        .filter(Boolean)
      return Array.from(new Set(role))
    },
    initCallBack() {
      this.treeIds.map((id) => {
        const node = this.$refs.table.table['store'].states.treeData[id]
        node.loaded = false
        node.expanded = false
      })
    },
    // 当点击展开列时发送获取子账号数据请求
    async expandChange(row) {
      if (typeof this.showExpand[row.id] === 'boolean') return
      this.showExpand[row.id] = true
      this.loading = true
      if (!this.expandPage[row.id])
        this.$set(this.expandPage, row.id, {
          pageIndex: 1,
          pageSize: 10
        })
      const res = await awaitResolve(
        subAccount(
          {
            mainAccountUid: row.id,
            page: this.expandPage[row.id]
          },
          'btn'
        )
      )
      if (res) {
        let { detail, page } = res
        this.expandData[row.id] = detail
        if (!detail.length) {
          this.showExpand[row.id] = false
        }
        this.$set(this.expandPageTotal, row.id, page.total)
      }
      this.loading = false
    },
    expandRefreshTableEventFun({ page, size }, row) {
      this.expandPage[row.id].pageIndex = page
      this.expandPage[row.id].pageSize = size
      this.showExpand[row.id] = 1
      this.expandChange(row)
    },
    // 查看勾选项
    handleSelectionChange(selection) {
      this.selectionData = selection
      //   console.log('selection', selection)
      if (selection.length === this.data.length) {
        // console.log('this.data.length', this.data)
        if (this.pageChangeValue) {
          this.selectionAll = true
        }
      } else {
        this.selectionAll = false
      }
      if (selection.length !== this.data.length && selection.length !== 0) {
        this.isIndeterminateData = true
      } else {
        this.isIndeterminateData = false
      }
      this.pageChangeValue = true
    },
    // 勾选表格全选
    selectAll(selection) {
      this.allSelectLength = selection.length
    },
    // 自己的全选按钮变化
    handelCheckAllChange() {
      this.$refs.multipleTable.table.toggleAllSelection()
    },

    controlWaybillCode(row) {
      let waybillDisplayByOrderStatus = `${row.waybillDisplayByOrderStatus}`
      let orderStatus = waybillDisplayByOrderStatus
      let dic = [
        {
          label: '排单中',
          value: IN_DISPATCH
        },
        {
          label: '生产中',
          value: IN_PRODUCTION
        },
        {
          label: '已发货',
          value: DELIVER
        }
      ]
      if (!find(dic, { value: orderStatus })) orderStatus = ''
      const h = this.$createElement
      this.$msgbox({
        title: '运单号显示',
        customClass: 'msgbox--normal',
        showCancelButton: true,
        message: h('div', null, [
          h('span', null, '该分销商在'),
          h('avueCrudSelect', {
            style: 'display: inline-block;width: 188px;margin: 0 8px;',
            props: {
              dic,
              size: 'small',
              clearable: false,
              value: orderStatus
            },
            on: {
              change(val) {
                orderStatus = val
              }
            },
            key: `${row.id}-${+new Date()}`
          }),
          h('span', null, '订单状态下显示运单号')
        ]),
        beforeClose: async (action, instance, done) => {
          if (action === 'confirm') {
            if (orderStatus !== waybillDisplayByOrderStatus) {
              const loading = this.$loading({ lock: true })
              let res = await awaitResolve(
                updateWaybillStatus({
                  id: row.id,
                  waybillDisplayByOrderStatus: orderStatus
                })
              )
              loading.close()
              if (!res) return
              this.init()
            }
            this.$message.success('操作成功')
          }
          done()
        }
      })
    },
    // 启用信用额度
    openCreditAmount(row) {
      this.$confirm(`确认是否${row.isEnabledCredit === ENABLE_CREDIT ? '关闭' : '启用'}该用户的信用额度`, '温馨提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        beforeClose: async (action, instance, done) => {
          try {
            if (action === 'confirm') {
              instance.confirmButtonLoading = true
              instance.confirmButtonText = '确定'
              const { code } = await updateEnabledCredit({
                id: row.id,
                isEnabledCredit: row.isEnabledCredit === ENABLE_CREDIT ? DISABLE_CREDIT : ENABLE_CREDIT
              })
              if ($SUC({ code })) {
                this.$message.success(`${row.isEnabledCredit === ENABLE_CREDIT ? '关闭' : '启用'}信用额度成功`)
                this.init()
              } else {
                this.$message.error(`${row.isEnabledCredit === ENABLE_CREDIT ? '关闭' : '启用'}启用信用额度失败`)
              }
            }
          } catch (e) {
            this.$message.error(`${row.isEnabledCredit === ENABLE_CREDIT ? '关闭' : '启用'}启用信用额度失败`)
          } finally {
            instance.confirmButtonLoading = false
            done()
          }
        }
      })
    },

    findByValue(dic, value) {
      return find(dic, { value })?.label || value
    }
  },
  created() {
    this.getVIPLevelList()
  }
}
</script>

<style scoped lang="scss">
.el-table {
  border-left: 1px solid #ebeef5;
}
.expandClass {
  width: 300px;
}
.before-line::before {
  margin-left: 0;
  content: '|';
  line-height: 1;
  font-size: 12px;
  margin: 0 10px;
  display: inline-block;
  transform: scaleX(0.5);
  color: #dcdee0;
}
.frozenOrThawClass:hover {
  color: $--color-primary;
}
.frozenClass:hover {
  color: red !important;
}

::v-deep {
  .el-table__body-wrapper {
    flex: 1;
    overflow-y: auto;
  }
}
</style>
